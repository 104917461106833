import "./styles.css";

import PartySocket from "partysocket";
import { client } from "../../s/websocks/packages/client/src/standalone";
import type { Schema } from "../../s/websocks/packages/cli/test";

// const socksClient = client<Schema>(
//   "wss://d2yo3t7kab5sh9.cloudfront.net/discord"
// );

declare const PARTYKIT_HOST: string;

let pingInterval: ReturnType<typeof setInterval>;

// Let's append all the messages we get into this DOM element
const output = document.getElementById("app") as HTMLDivElement;

// Helper function to add a new line to the DOM
function add(text: string) {
  output.appendChild(document.createTextNode(text));
  output.appendChild(document.createElement("br"));
}

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

let p0 = performance.now();
let p1 = performance.now();

// You can even start sending messages before the connection is open!
conn.addEventListener("message", (event) => {
  p1 = performance.now();
  add(`Received -> ${p1 - p0}ms ping`);
});

// Let's listen for when the connection opens
// And send a ping every 2 seconds right after
conn.addEventListener("open", () => {
  add("Connected!");
  add("Sending a ping every 1.0 seconds...");
  // TODO: make this more interesting / nice
  p0 = performance.now();
  conn.send("ping");
});

// socksClient.on.test((data) => {
//   p1 = performance.now();
//   add(`Received -> ${p1 - p0}ms ping`);
// });

// socksClient.on.open(() => {
//   add("Connected!");
//   add("Sending a ping every 1 seconds...");

//   clearInterval(pingInterval);
//   pingInterval = setInterval(() => {
//     p0 = performance.now();
//     socksClient.send.test("ping");
//   }, 1000);
// });
